// 路由不添加额外字段的白名单
export const excludeList = [
  "/crm/login",
  "/crm/upload",
  "/crm/project/all",
  "/crm/role",
  "/crm/role/updateRole",
  "/crm/roleProject/page",
  "/crm/roleProject/updateRole",
  "/crm/userProject/list",
  "/crm/user/reset",
  "/crm/account/bindList",
  "/crm/account/unbind",
  "/crm/account/associatedLogin",
  "/crm/wxLogin/callBack",
  "/crm/roleProject",
  "/crm/dimensionMark/updateMarkWaterWarringInfo",
];
