import React, { useState } from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
// eslint-disable-next-line import/no-cycle
import HeaderView from "./header";
import { MenuView } from "./menu";
import "./index.scss";
import { TitleView } from "./title";

const { Content } = Layout;
function LayoutView() {
  React.useEffect(() => {
    // console.log("Layout ！！！");
  }, []);
  const [state, setState] = useState(true);
  const ChangeOpen = (status: boolean) => {
    setState(status);
  };
  return (
    <Layout className="layout-view">
      <TitleView ChangeOpen={ChangeOpen} />
      <Layout>
        <MenuView state={state} setState={setState} />
        <Layout style={{ background: "#0c121a" }}>
          <HeaderView />
          <Content className="layout-content">
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
export default LayoutView;
