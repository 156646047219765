import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";

export function FormList(props: any) {
  const { onFinish, item } = props;
  const [form] = Form.useForm();
  const onFinishFn = (data: any) => {
    onFinish(item, data);
  };
  useEffect(() => {
    form.setFieldsValue({ ...item });
  }, []);
  return (
    <Form
      form={form}
      onFinish={onFinishFn}
      autoComplete="off"
      name={item.appId}
      labelCol={{ span: 5 }}
      wrapperCol={{ offset: 1 }}
    >
      <Form.Item
        label="用户名"
        name="username"
        rules={[{ required: true, message: "请输入用户名" }]}
      >
        <Input disabled={!!item.appId} placeholder="请输入用户名" />
      </Form.Item>
      <Form.Item
        label="密码"
        name="password"
        rules={[{ required: true, message: "请输入密码" }]}
      >
        <Input.Password
          disabled={!!item.appId}
          visibilityToggle={false}
          placeholder="请输入密码"
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        {item.appId ? (
          <Button htmlType="submit">解除绑定</Button>
        ) : (
          <Button type="primary" htmlType="submit">
            关联绑定
          </Button>
        )}
      </Form.Item>
    </Form>
  );
}
