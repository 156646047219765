import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menuList:
    JSON.parse(localStorage.getItem("TMJ-ADMIN-MENU-LIST") as string) || [],
  collapsedActive: false,
  menuId:
    JSON.parse(localStorage.getItem("TMJ-ADMIN-MENU-ID") as string) || null,
};

export const menuSlice = createSlice({
  name: "menuSlice",
  initialState,
  reducers: {
    SET_MENU(state, action) {
      state.menuList = action.payload;
      localStorage.setItem(
        "TMJ-ADMIN-MENU-LIST",
        JSON.stringify(action.payload)
      );
    },
    SET_COLLAPSE(state, action) {
      state.collapsedActive = action.payload;
    },
    REMOVE_MENU(state) {
      localStorage.setItem("TMJ-ADMIN-MENU-LIST", JSON.stringify([]));
      state.menuList = [];
    },
    SET_MENU_ID(state, action) {
      localStorage.setItem("TMJ-ADMIN-MENU-ID", JSON.stringify(action.payload));
      state.menuId = action.payload;
    },
    REMOVE_MENU_ID(state) {
      localStorage.setItem("TMJ-ADMIN-MENU-ID", JSON.stringify(null));
      state.menuId = null;
    },
  },
});

export const {
  SET_MENU,
  SET_COLLAPSE,
  REMOVE_MENU,
  SET_MENU_ID,
  REMOVE_MENU_ID,
} = menuSlice.actions;

export const Menu = menuSlice.reducer;
