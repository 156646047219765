// import { useDispatch } from "react-redux";
import { message } from "antd";
import type { routerItem } from "../router";
import { versionMenuEntity } from "../api/modules/superManage/interface";

// 菜单栏类型
export interface menuItem {
  children: menuItem[];
  domType: string;
  icon: never;
  id: number;
  menuName: string;
  pathInfo: never;
  pid: number;
}
// 遍历路由树形结构
const getRouteList = (router: routerItem[]) => {
  const list = router;
  const arr: routerItem[] = [];
  const getRoute = (item: routerItem) => {
    arr.push(item);
    if (item.children) {
      item.children.map((i: routerItem) => {
        getRoute(i);
      });
    }
  };
  if (list.length) {
    list.map((i: routerItem) => {
      getRoute(i);
    });
  }
  return arr;
};

// 通过path查询路由
export const GetRouteByPath = (path: string, router: routerItem[]) => {
  const arr = getRouteList(router);
  return arr.find((i) => i.path === path);
};

// 通过path查询路由
export const GetRouteByID = (id: number, router: routerItem[]) => {
  const arr = getRouteList(router);
  return arr.find((i) => i.meta.id === id);
};

// 通过id查找当前展开的父项
export const GetRoutePidByID = (id: number, menuList: routerItem[]) => {
  try {
    return menuList.find(
      (v: routerItem) =>
        (v.children &&
          v.children.some((item: routerItem) => item.meta.id === id)) ||
        (v.btns && v.btns.some((item: routerItem) => item.meta.id === id))
    );
  } catch (e) {
    // 判错冗余处理
    localStorage.setItem("TMJ-ADMIN-MENU-LIST", JSON.stringify([]));
    window.location.href = `${window.location.origin}/#/login`;
    message.error("出错了，请重新登录！").then();
    return { meta: { id: 1000, title: "首页" } };
  }
};

// 获取当前用户所有可用的id列表
export const GetRouteIDList = (list: routerItem[]) => {
  const arr: number[] = []; // 全部可用的ID
  // 递归生成所有可用路由的id
  const getID = (item: routerItem) => {
    arr.push(item.meta.id);
    if (item.children) {
      item.children.map((i) => {
        getID(i);
      });
    }
    if (item.btns) {
      item.btns.map((i) => {
        getID(i);
      });
    }
  };
  if (list.length) {
    list.map((i: routerItem) => {
      getID(i);
    });
  }
  return arr;
};
// 过滤路由
export const filterRouter = (list: routerItem[], ids: number[]) => {
  return list.reduce((pre: any, cur: any) => {
    const obj = { ...cur };
    const bool: boolean = ids.some((v: number) => v === obj.meta.id);
    if (bool) {
      if (obj.children) {
        obj.children = filterRouter(obj.children, ids);
      }
      pre.push(obj);
    }
    return pre;
  }, []);
};
// 路由是否存在
export const isRoute = (list: any, pathname: string) => {
  return list.some(
    (v: any) =>
      v.path === pathname || (v.children && isRoute(v.children, pathname))
  );
};

// 根据路由表获取可用的叶子节点
export const getLeafRoute = (ids: number[], list: any) => {
  return list.reduce((pre: any, cur: any) => {
    const bol: boolean = ids.some((id: number) => id === cur.meta.id);
    if (bol && cur.children) {
      pre.push(...getLeafRoute(ids, cur.children));
    }
    if (bol && !cur.children) {
      pre.push(cur);
    }
    return pre;
  }, []);
};
// 获取菜单选中叶子节点
export const getSelectLeaf = (data: versionMenuEntity[]) => {
  return data.reduce((pre: number[], cur: versionMenuEntity) => {
    if (
      cur.selected === "YES" &&
      (!cur.childNodes || cur.childNodes.length === 0)
    ) {
      pre.push(cur.id);
    }
    if (cur.childNodes) {
      pre.push(...getSelectLeaf(cur.childNodes));
    }
    return pre;
  }, []);
};
// 获取菜单选中所有节点
export const getAllSelectKey = (data: versionMenuEntity[]) => {
  return data.reduce((pre: number[], cur: versionMenuEntity) => {
    if (cur.selected === "YES") {
      pre.push(cur.id);
    }
    if (cur.childNodes) {
      pre.push(...getAllSelectKey(cur.childNodes));
    }
    return pre;
  }, []);
};
// get all tree ids
export const getTreeIds = (data: versionMenuEntity[]) => {
  return data.reduce((pre: number[], cur: versionMenuEntity) => {
    if (cur.childNodes && cur.childNodes.length !== 0) {
      pre.push(...getTreeIds(cur.childNodes));
    }
    pre.push(cur.id);
    return pre;
  }, []);
};
// 是否全选
export const isSelectAll = (data: versionMenuEntity[]): boolean => {
  return data.every((v) => v.selected === "YES" && isSelectAll(v.childNodes));
};
// get all tree leaf ids
export const getTreeLeafIds = (data: versionMenuEntity[]) => {
  return data.reduce((pre: number[], cur: versionMenuEntity) => {
    if (Array.isArray(cur.childNodes) && cur.childNodes.length !== 0) {
      pre.push(...getTreeLeafIds(cur.childNodes));
    } else {
      pre.push(cur.id);
    }
    return pre;
  }, []);
};
