import axios from "axios";
import { message } from "antd";
import Cookies from "js-cookie";
import { store } from "../store";
import { excludeList } from "./exclude";

// 链接地址
const { REACT_APP_BASE_URL } = process.env;
const Axios = axios.create({
  baseURL: REACT_APP_BASE_URL,
  timeout: 60000,
});

Axios.interceptors.request.use(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (config: any) => {
    // 浅浅拷贝一份数据
    const configClone = { ...config };
    // 获取当前token
    const token = Cookies.get("TIANMU_TOKEN");
    // 获取用户信息
    const { userInfo } = store.getState().UserInfo;
    // 给接口添加token
    if (token) {
      configClone.headers.token = token;
    }
    // 在post的时候对传的参数添加公共参数，不需要每个接口都添加
    if (!excludeList.includes(config.url) && config.url.includes("/crm/")) {
      // 过滤为空项
      configClone[["post", "put"].includes(config.method) ? "data" : "params"] =
        Object.fromEntries(
          Object.entries({
            sysType: 1,
            appId: userInfo.appId ? userInfo.appId : 0,
            versions: userInfo.versions ? userInfo.versions : "NULL",
            status: "NORMAL",
            ...config[
              ["post", "put"].includes(config.method) ? "data" : "params"
            ],
          }).filter((i) => i[1] !== "" && i[1] !== undefined && i[1] !== null)
        );
    }
    // console.log("configClone -->", configClone);
    return configClone;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res instanceof Blob) {
      return Promise.resolve(res);
    }
    // 几个服务返回的code可能不一样，在这里添加条件
    if (res.code === 200) {
      return Promise.resolve(res);
    }
    // 此处需要重新登录，并清除缓存的token
    if ([401, 505].includes(res.code)) {
      message.warning(res.msg, 1).then(() => {
        window.location.href = `${window.location.origin}/#/login`;
        Cookies.remove("TIANMU_TOKEN");
      });
      return Promise.reject(res);
    }
    // 资源未找到，但可以不用重新登录
    if ([403, 404].includes(res.code)) {
      message.error(res.msg).then();
      return Promise.reject(res);
    }
    if (res.code === 505) {
      message.error(res.msg).then();
      return Promise.reject(res);
    }

    // 网络错误或链接超时
    message.error(res.msg).then();
    return Promise.reject(res);
  },
  (error) => {
    if (error.message) {
      message.error(error.message).then();
      // console.log(error);
    }
    return Promise.reject(error);
  }
);

export default Axios;
