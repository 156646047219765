import { TaskListType, taskMsgDetailType } from "./type";
import Axios from "../../request";

export class MessageManageAPI {
  // 任务消息-列表
  static getListTaskMessage: TaskListType = (data) =>
    Axios.post("/tss/notice/page", data);

  //   任务消息-详情
  static getTaskMsgDetail: taskMsgDetailType = (data) =>
    Axios.post(`/tss/notice/info/${data?.id}`);
}
