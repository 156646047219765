import Axios from "../../request";
import { ResponseListType, ResponseType } from "../../interface";
import {
  callBack,
  taskType,
  taskInfoType,
  processType,
  processLabel,
  projectLabel,
} from "./interface";

export class TaskManageAPI {
  // 机构下拉
  static getOrganList(params?: object) {
    return Axios.get("/crm/organization/tree", { params });
  }

  // 指定流程所有项目详情
  static processAll(id: number) {
    return Axios.get(`/tss/process/all/${id}`);
  }

  // 单个项目流程生效状态,0 失效 1生效,
  static processAvailable(id: number, projectId: number, available: number) {
    return Axios.put(`/tss/process/${id}/${projectId}/${available}`);
  }

  // 关联任务统计
  static taskPageList(params: any) {
    return Axios.post(`/tss/task/page`, { ...params });
  }

  // 用户下拉
  static getUserList(params?: object) {
    return Axios.get("/crm/user/approvalUserList", { params });
  }

  // 项目列表
  static getObjectList() {
    return Axios.get("/tss/process/projectBox");
  }

  // 项目列表
  static getObjectInfo(id: number, projectId: number) {
    return Axios.get(`/tss/process/${id}/${projectId}`);
  }

  // 任务列表
  static getTaskList(params: Partial<taskType>) {
    return Axios.post("/tss/task/page", { ...params });
  }

  // 导出成果
  static exportWord(params: any) {
    return Axios({
      url: "/tss/task/export-word",
      method: "GET",
      params,
      responseType: "blob",
    });
  }

  // 任务详情
  static getTaskInfo(id: number) {
    return Axios.get(`/tss/task/${id}`);
  }

  // 任务图片
  static getTaskImg(id: number) {
    return Axios.get(`/tss/task/image/${id}`);
  }

  // 任务视频
  static getTaskVdo(id: number) {
    return Axios.get(`/tss/task/video/${id}`);
  }

  // 任务删除
  static deleteTask(id: number) {
    return Axios.delete(`/tss/task/${id}`);
  }

  // 任务类型
  static getProcessList(params: Partial<processType>) {
    return Axios.post("/tss/process/page", { ...params });
  }

  // 任务类型详情
  static getProcessInfo(id: number) {
    return Axios.get(`/tss/process/${id}`);
  }

  // 流程标签选择下拉
  static getProcessBox() {
    return Axios.get(`/tss/label/labelBox`);
  }

  // 任务类型编辑
  static putProcessInfo(params: Partial<processLabel>) {
    return Axios.put(`/tss/process`, { ...params });
  }

  // 任务类型新建
  static addProcessInfo(params: Partial<processLabel>) {
    return Axios.post(`/tss/process`, { ...params });
  }

  // 任务类型删除
  static deleteProcess(id: number) {
    return Axios.delete(`/tss/process/${id}`);
  }

  // 操作员配置新建
  static addProjectInfo(data: Partial<projectLabel>) {
    return Axios({
      url: "/tss/process/projectAndProcessConfMore",
      method: "POST",
      data,
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
    });
  }

  // 操作员配置编辑
  static editProjectInfo(data: Partial<projectLabel>) {
    // console.log(data);
    return Axios({
      url: "/tss/process/projectAndProcessUpdMore",
      method: "POST",
      data,
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
    });
  }

  // 流程标签
  static getLabelList(params: Partial<processType>) {
    return Axios.post("/tss/label/page", { ...params });
  }

  // 流程标签编辑
  static putLabelInfo(params: Partial<processLabel>) {
    return Axios.put(`/tss/label`, { ...params });
  }

  // 流程标签新建
  static addLabelInfo(params: Partial<processLabel>) {
    return Axios.post(`/tss/label`, { ...params });
  }

  // 流程标签详情
  static getLabelInfo(id: number) {
    return Axios.get(`/tss/label/${id}`);
  }

  // 流程标签删除
  static deleteLabel(id: number) {
    return Axios.delete(`/tss/label/${id}`);
  }
}
