import Axios from "../../request";

export class DataCenterAPI {
  // 订单列表
  static listOrderManager = (data: unknown) =>
    Axios.post("/orderservlet/ordertask/listOrderManager", data);

  // 订单状态
  static listOrderStatus = (data: unknown) =>
    Axios.get("orderservlet/ordertask/listOrderStatus", { params: data });
}
