import { configureStore } from "@reduxjs/toolkit";
import { Menu } from "./module/menu";
import { UserInfo } from "./module/userInfo";

export const store = configureStore({
  reducer: {
    Menu,
    UserInfo,
  },
});
