import React from "react";
import { Button } from "antd";
import "./index.scss";
import { useNavigate } from "react-router-dom";

function PageNotFound() {
  const navigate = useNavigate();
  // 返回上一页
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="error-page">
      <div className="error-page-container">
        <div className="error-page-main">
          <h3>
            <strong>404</strong>很抱歉，您要访问的页面不存在！
          </h3>
          <div className="error-page-actions">
            <div>
              <h4>可能原因：</h4>
              <ol>
                <li>输入的网址不正确</li>
                <li>暂无权限</li>
                <li>服务器开小差了</li>
              </ol>
            </div>
            <div>
              <h4>可以尝试：</h4>
              <ol>
                <li>
                  <Button
                    className="error-page-back"
                    onClick={handleBack}
                    type="text"
                  >
                    返回上一页
                  </Button>
                </li>
                <li>联系管理员获取权限</li>
                <li>等待服务恢复</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PageNotFound;
