import Axios from "../../request";
import {
  addBigScreenType,
  deleteBigScreenType,
  getBigScreenType,
  updateBigScreenType,
} from "./interface";
import { filterObjAttr } from "../../../utils";

export class SystemSettingAPI {
  // 订单列表
  static listAvlRoleInfo = (data: unknown) =>
    Axios.get("/userservlet/roleinfo/listAvlRoleInfo", { params: data });

  // 账号管理分页
  static listUserAccountPage = (data: unknown) =>
    Axios.post("/userservlet/useraccount/listUserAccountPage", data);

  // 角色权限列表
  static listRoleInfoPage = (data: unknown) =>
    Axios.post("/userservlet/roleinfo/listRoleInfoPage", data);

  // 新增角色权限
  static insertRoleInfo = (data: unknown) =>
    Axios.post("/userservlet/roleinfo/insertRoleInfo", data);

  // 删除角色权限
  static removeRoleInfo = (data: unknown) =>
    Axios.post("/userservlet/roleinfo/removeRoleInfo", data);

  // 修改角色权限
  static updateRoleInfo = (data: unknown) =>
    Axios.post("/userservlet/roleinfo/updateRoleInfo", data);

  //   可视化管理-大屏列表
  static getBigScreen: getBigScreenType = (params) =>
    Axios.get("/crm/big-screen", { params });

  static addBigScreen: addBigScreenType = (data) =>
    Axios.post("/crm/big-screen", data);

  static updateBigScreen: updateBigScreenType = (data) =>
    Axios.put(`/crm/big-screen/${data?.id}`, filterObjAttr(data, ["id"]));

  static deleteBigScreen: deleteBigScreenType = (data) =>
    Axios.delete(`/crm/big-screen/${data?.id}`);
}
