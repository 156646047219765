import Axios from "../../request";
import { PasswordRules } from "./interface";
import { ResponseListTypeDms, ResponseType } from "../../interface";
import { InfoType } from "../../../pages/deviceCenter/productType/interface";

export class CommonAPI {
  // 登录接口
  static platformLogin = (data: FormData): Promise<ResponseType<never>> =>
    Axios.post("/crm/login", data);

  // 推广平台配置
  static updatePromotionApp = (data: {
    icon: string;
    id: number;
    name: string;
    remake: string;
    type: string;
    createdDate: string;
    url?: string;
    manageUrl?: string;
    passWord?: any;
    userName?: string;
  }): Promise<ResponseListTypeDms<never>> =>
    Axios.put("/crm/application/updatePromotionApp", data);

  // 当前用户项目列表
  static getProjectListByUser = (): Promise<ResponseType<never>> =>
    Axios.get("/crm/user/getProjectListByUser");

  // 当前用户权限
  static getManagePermission = (
    projectId?: number
  ): Promise<ResponseType<never>> =>
    Axios.get("/crm/user/managePermission", { params: { projectId } });

  //  上传
  static uploadFile = (data: FormData): Promise<ResponseType<string>> => {
    return Axios.post("/crm/upload", data);
  };

  // 个人用户信息
  static getPersonalCenter = (id: number): Promise<ResponseType<InfoType>> =>
    Axios.get(`/crm/personalCenter/list?id=${id}`);

  static updatePassword = (
    params: PasswordRules
  ): Promise<ResponseListTypeDms<never>> =>
    Axios.get(`/crm/personalCenter/updatePassword`, { params });

  // 外部应用下拉列表
  static getApplication = (params: {
    userId: number;
    roleId: number;
  }): Promise<ResponseType<never>> =>
    Axios.get(`/crm/personalCenter/application`, { params });

  // 微信登录
  static wxLoginCallBack = (params: {
    code: string;
    state: string;
  }): Promise<ResponseType<never>> =>
    Axios.get(`/crm/wxLogin/callBack`, { params });

  // 当前账号绑定的微信
  static getWxList = (): Promise<ResponseType<never>> =>
    Axios.get(`/crm/wxLogin/list`);

  // 获取所有项目管理员列表 CommonAPI.getProjectUserlist()
  static getProjectUserlist = (params: {
    roleld?: string;
    tenantId: number;
    userName?: string;
    userAccount?: string;
    pageSize: number;
  }): Promise<ResponseType<any>> =>
    Axios.get(`/crm/userProject/getProjectUserlist`, { params });

  // 微信解绑
  static wxLoginUntie = (): Promise<ResponseType<never>> =>
    Axios.get(`/crm/wxLogin/untie`);

  // v3版本管理-获取推广应用列表
  static getPromotionApp = (): Promise<ResponseType<any>> =>
    Axios.get(`/crm/application/getPromotionApp`);
}
