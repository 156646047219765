import React, { useEffect, useState } from "react";
import { Checkbox, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { SuperManageAPI } from "../../../../../../../api";
import { aiListType } from "../../../../../../../api/modules/superManage/interface";
import { msgResponseType } from "../../../../../../../api/interface";
// 增值ai算法-列表返回类型
type aiListReqType = msgResponseType<aiListType>;
const AiAlgorithmService = function () {
  const tableColumns: ColumnsType<any> = [
    {
      title: "AI算法",
      align: "center",
      dataIndex: "typeName",
    },
    {
      title: "描述",
      align: "center",
      dataIndex: "aiAlgDesc",
    },
    {
      title: "是否选配",
      align: "center",
      dataIndex: "hasConfiged",
      render: (text: boolean) => <Checkbox checked={text} />,
    },
  ];
  const [tableData, setTableData] = useState<aiListReqType>([]);

  // 获取ai算法列表
  const getAiList = () => {
    SuperManageAPI.getAiList().then((res) => {
      setTableData((res.data || []).filter((v) => v.hasConfiged));
    });
  };
  useEffect(() => {
    getAiList();
  }, []);
  return (
    <div className="ai-algorithm-service">
      <Table
        columns={tableColumns}
        dataSource={tableData}
        rowKey="type"
        pagination={false}
      />
    </div>
  );
};
export default AiAlgorithmService;
