import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { Form, message } from "antd";
import { Map, Marker, Polygon } from "react-amap";
import "./index.scss";
// eslint-disable-next-line import/order
import Axios from "axios";

interface xmlType {
  data?: string;
  headers?: object;
}

function AddEditModal() {
  const [mapSearchForm] = Form.useForm();
  const [autoCompleteObj, setAutoCompleteObj] = useState<any>();
  const [geocoderObj, setGeocoderObj] = useState<any>();
  const [locationList, setLocationList] = useState<any>([]);
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );
  const [searchName, setSearchName] = useState<string>("");
  // map
  const [centerLng, setCenterLng] = useState<number>(0);
  const [centerLat, setCenterLat] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(10);
  // marker
  const [markerLng, setMarkerLng] = useState<number>(0);
  const [markerLat, setMarkerLat] = useState<number>(0);
  const [formattedAddress, setFormattedAddress] = useState<string>("");
  // 标记定位
  const [allowSign, setAllowSign] = useState<boolean>(false);
  const [style, setStyle] = useState<any>({
    fillColor: "#0088ff",
  });
  const [xmlHtml, setXmlHtml] = useState<any>();
  const path = useState<any>([
    [116.447451, 39.894004],
    [116.347438, 39.794196],
    [116.247399, 39.994385],
  ]);

  const getAddressByLatLng = (lat: number, lng: number) => {
    //  逆地理编码
    geocoderObj.getAddress([lng, lat], function (status: string, result: any) {
      if (status === "complete" && result.info === "OK") {
        // result为对应的地理位置详细信息
        setFormattedAddress(result.regeocode.formattedAddress);
      }
    });
  };
  // 下拉搜索
  const locationSearch = (str: string) => {
    setSearchName(str);
    if (str) {
      autoCompleteObj.search(str, (status: any, result: any) => {
        if (status === "complete") {
          const optionList = result.tips.map((v: any) => ({
            value: v.name,
            label: (
              <div>
                <span>{v.name}</span>
                <span
                  style={{ color: "#999", marginLeft: "5px", fontSize: "8px" }}
                >
                  {v.district}
                </span>
              </div>
            ),
          }));
          setOptions(optionList);
          setLocationList(result.tips || []);
        } else {
          setLocationList([]);
        }
      });
    } else {
      setLocationList([]);
      setOptions([]);
    }
  };
  // 下拉选择
  const locationSelect = (value: string) => {
    setSearchName(value);
    const locationObj = locationList.find((v: any) => v.name === value);
    let lat = 0;
    let lng = 0;
    if (locationObj) {
      if (locationObj.location) {
        lat = locationObj.location.lat;
        lng = locationObj.location.lng;
        setCenterLng(lng);
        setCenterLat(lat);
        getAddressByLatLng(lat, lng);
      } else {
        geocoderObj.getLocation(
          locationObj.name,
          function (status: string, result: any) {
            if (status === "complete" && result.info === "OK") {
              // result中对应详细地理坐标信息
              lat = result.geocodes[0].location.lat;
              lng = result.geocodes[0].location.lng;
              setCenterLng(lng);
              setCenterLat(lat);
              getAddressByLatLng(lat, lng);
            }
          }
        );
      }
      setZoom(18);
    }
  };
  // 地图事件
  const mapEvents = {
    created: () => {
      const { AMap } = window as any;
      AMap.plugin("AMap.Autocomplete", function () {
        const autoOptions = {
          city: "全国",
        };
        const autoComplete = new AMap.Autocomplete(autoOptions);
        setAutoCompleteObj(autoComplete);
      });
      AMap.plugin("AMap.Geolocation", function () {
        const geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
          // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
          buttonOffset: new AMap.Pixel(10, 20),
          //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          zoomToAccuracy: true,
          //  定位按钮的排放位置,  RB表示右下
          buttonPosition: "RB",
        });
        geolocation.getCurrentPosition((status: any, result: any) => {
          if (status === "complete") {
            setCenterLat(result.position.lat);
            setCenterLng(result.position.lng);
            setFormattedAddress(result.formattedAddress);
          }
        });
      });
      AMap.plugin("AMap.Geocoder", function () {
        const geocoder = new AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          city: "全国",
        });
        setGeocoderObj(geocoder);
        // 使用geocoder做地理/逆地理编码
      });
    },
    click: (e: any) => {
      if (allowSign) {
        setMarkerLat(e.lnglat.lat);
        setMarkerLng(e.lnglat.lng);
        getAddressByLatLng(e.lnglat.lat, e.lnglat.lng);
      }
    },
  };
  // 查询
  const searchAddress = () => {
    if (searchName) {
      geocoderObj.getLocation(
        searchName,
        function (status: string, result: any) {
          if (status === "complete" && result.info === "OK") {
            // result中对应详细地理坐标信息
            setCenterLng(result.geocodes[0].location.lng);
            setCenterLat(result.geocodes[0].location.lat);
            setZoom(18);
            setFormattedAddress(result.geocodes[0].formattedAddress);
          }
        }
      );
    } else {
      message.warning("请输入地点");
    }
  };
  // 标记定位
  const allowMapMarkerEvent = () => {
    setAllowSign(true);
    setMarkerLat(centerLat);
    setMarkerLng(centerLng);
  };
  // 防抖
  const debounce = (fn: any, wait: number) => {
    let timeout: any;
    return function () {
      // eslint-disable-next-line prefer-rest-params
      const args = arguments;
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(function () {
        fn.apply("", args);
      }, wait);
    };
  };
  // 取面对象 中心点
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const calculateCenter = (pth: any[]) => {
    const total = pth.length;
    let X = 0;
    let Y = 0;
    pth.forEach(function (lnglat) {
      const lng = lnglat[0];
      const lat = lnglat[1];
      X += lng;
      Y += lat;
    });
    X /= total;
    Y /= total;
    // console.log(X, Y, pth);
    setCenterLng(X);
    setCenterLat(Y);
  };

  // 测试数据
  const [url, setUrl] = useState("");
  useEffect(() => {
    // setUrl(JSON.parse(decodeURI(window.location.hash.split("?")[1])));
    setUrl(decodeURI(window.location.hash.split("?")[1]));
    calculateCenter(path[0]);
    // Axios.get(
    //   "http://218.205.198.56:8090/iserver/services/map-20220818QingHaiDaTongXianNiShiLiu-YunPingTaiYanShiYong/wmts100/1.0.0/WMTSCapabilities.xml"
    // ).then((res: any) => {
    //   const dat = res.data.substring(1, res.data.length - 1);
    //   setXmlHtml(dat);
    //   // console.log(dat);
    // });
  });
  return (
    <div className="preview">
      <div className="content">预览地图拿到的数据路径：{url}</div>

      <div className="map-wrap">
        <div className="search-wrap">
          {/* <Form form={mapSearchForm}>
            <Row gutter={{ xs: 8, sm: 16, md: 16 }}>
              <Col span={14}>
                <Form.Item name="address">
                  <AutoComplete
                    placeholder="搜地点"
                    onSearch={debounce(locationSearch, 1000)}
                    onSelect={locationSelect}
                    options={options}
                  />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item>
                  <Button
                    type="primary"
                    style={{ marginRight: "10px" }}
                    onClick={searchAddress}
                  >
                    查询
                  </Button>
                  <Button type="primary" onClick={allowMapMarkerEvent}>
                    标记定位
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form> */}
        </div>
        <div className="map-content">
          {/* <Map
            amapkey="2d6d0eff0dc7174a03b43305c24ddcaf"
            events={mapEvents}
            center={
              centerLat && centerLng
                ? { longitude: centerLng, latitude: centerLat }
                : undefined
            }
            zoom={zoom}
          >
            <Marker
              visible={!!(markerLat && markerLng)}
              position={{ longitude: markerLng, latitude: markerLat }}
            />
          </Map> */}
          <Map
            amapkey="2d6d0eff0dc7174a03b43305c24ddcaf"
            events={mapEvents}
            center={[centerLng, centerLat] || undefined}
            zoom={zoom}
          >
            <Polygon path={path[0]} style={style} />
          </Map>
        </div>
        {/* <div
          className="map-content"
          // eslint-disable-next-line react/no-danger  html代码片段
          // dangerouslySetInnerHTML={{ __html: xmlHtml }}
        >
          {xmlHtml}
          <iframe
            className="xmlH"
            sandbox=""
            title="CCC"
            src="http://218.205.198.56:8090/iserver/services/map-20220818QingHaiDaTongXianNiShiLiu-YunPingTaiYanShiYong/wmts100/1.0.0/WMTSCapabilities.xml"
          />
        </div> */}
      </div>
    </div>
  );
}

export default AddEditModal;
