import React, { useEffect, useState } from "react";
import "./index.scss";
import { useSelector, useDispatch } from "react-redux";

import { TableType } from "../../interface";
// interface propType {}
// 处理机构下拉菜单
// eslint-disable-next-line prefer-const

const listArray = [
  {
    id: 1,
    label: "登录账号",
    key: "userAccount",
  },
  {
    id: 2,
    label: "用户名称",
    key: "userName",
  },
  {
    id: 3,
    label: "微信昵称",
    key: "wxName",
  },
  {
    id: 4,
    label: "UID",
    key: "uid",
  },
  {
    id: 5,
    label: "所属角色",
    key: "roleName",
  },
  {
    id: 6,
    label: "所属机构",
    key: "tenantName",
  },
  {
    id: 7,
    label: "手机号码",
    key: "phone",
  },
  {
    id: 8,
    label: "邮箱",
    key: "email",
  },
  {
    id: 9,
    label: "创建时间",
    key: "createdDate",
  },
  {
    id: 10,
    label: "账号有效期",
    key: "validityDate",
  },
];
export function Information(props: TableType) {
  const { infoUser } = props;
  return (
    <div className="info-box">
      <div className="info-table-left">
        {listArray.map((item: { id: number; label: string; key: string }) => {
          return (
            <div key={item.id} className="table-item">
              {item.label}
            </div>
          );
        })}
      </div>
      <div className="info-table-right">
        {listArray.map((item: { id: number; label: string; key: string }) => {
          return (
            <div key={item.id} className="table-item">
              {infoUser[item.key]}
            </div>
          );
        })}
      </div>
    </div>
  );
}
