import React, { useState } from "react";
import "./index.scss";
import AiAlgorithmService from "./components/AiAlgorithmService";
import FlowStorageService from "./components/flowStorageService";

const AddService = function () {
  const menuList = [
    {
      id: 1,
      name: "流量和存储服务",
    },
    {
      id: 2,
      name: "AI算法服务",
    },
  ];
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(1);

  const tabChangeEvent = (index: number) => {
    setCurrentTabIndex(index);
  };
  return (
    <div className="personal-add-service">
      <div className="personal-add-service-menu">
        {menuList.map((v, index) => (
          <div
            className={`menu-item ${
              currentTabIndex === index + 1 ? "menu-item-active" : ""
            }`}
            key={v.id}
            onClick={() => tabChangeEvent(index + 1)}
          >
            {v.name}
          </div>
        ))}
      </div>
      <div className="personal-add-service-table">
        {currentTabIndex === 1 ? (
          <FlowStorageService />
        ) : (
          <AiAlgorithmService />
        )}
      </div>
    </div>
  );
};
export default AddService;
