// 不能输入数字，其他可惜输入
export const exceptNumber = (val: any) => {
  val.target.value = val.target.value
    .replace(/1?(\d|([1-9]\d+))(.\d+)?$/g, "")
    .replace(/\s/g, "");
};

// 只能输入正整数
export const onlyNumberPositive = (val: any) => {
  // eslint-disable-next-line eqeqeq
  if (val.target.value == 0) {
    val.target.value = val.target.value.replace(/0/g, "");
  }
  val.target.value = val.target.value.replace(/\D/g, "");
};

// 不能输入汉字，其他可输入
export const exceptChinese = (val: any) => {
  val.target.value = val.target.value
    .replace(/[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g, "")
    .replace(/\s/g, "");
};

// 只能输入字母和中文，不能输入数字和符号
export const onlyCharacter = (val: any) => {
  val.target.value = val.target.value
    .replace(/[^a-zA-Z\u4E00-\u9FA5]/g, "")
    .replace(/\s/g, "");
};

// 手机号输入，限制11位
export const phoneNumber = (val: any) => {
  if (val.target.value.length > 11) {
    val.target.value = val.target.value.slice(0, 11);
  } else {
    val.target.value = val.target.value.replace(/\D/g, "");
  }
};

// 开头不能输入空格
export const noSpaceFront = (val: any) => {
  val.target.value = val.target.value.replace(/^\s/g, "");
};
