import React, { useEffect, useState } from "react";
import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "@ant-design/icons";
import { useSelector } from "react-redux";
// eslint-disable-next-line import/no-cycle
import { router, routerItem, whiteRouter } from "../../../router/route";

import {
  GetRouteByID,
  GetRouteByPath,
  GetRouteIDList,
  GetRoutePidByID,
} from "../../../utils/router";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

// 转换侧边栏数据
const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
};
interface propsType {
  state: boolean;
  setState: (status: boolean) => void;
}
export function MenuView(props: propsType) {
  const { state: status, setState } = props;

  // 侧边栏是否折叠状态
  const collapsedActive = useSelector(
    (state: any) => state.Menu.collapsedActive
  );
  // 当前用户可用菜单
  const menuList = useSelector((state: any) => state.Menu.menuList);
  // 菜单路由列表
  const [items, setItems] = useState<MenuItem[]>([]);

  // 当选选中的项目
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  // 当选选中的项目的subMenu
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  // 当前路由
  const location = useLocation();

  // 路由跳转钩子
  const navigate = useNavigate();

  // 动态改变项目的id
  const menuId = useSelector((state: any) => state.Menu.menuId);

  // 获取真正需要显示的路由
  const GetRouteMenu = (data: routerItem[], routers: routerItem[]) => {
    return routers
      .filter((x) => GetRouteIDList(data).includes(x.meta.id) && !x.meta.hidden) // 过滤非权限路由
      .reduce((pre: any, cur) => {
        if (!cur.noShow) {
          let children = [];
          if (cur.children) {
            children = GetRouteMenu(data, cur.children);
          }
          pre.push({
            key: cur.meta.id,
            icon:
              cur.meta.icon &&
              (cur.meta.customIcon ? (
                <Icon component={cur.meta.icon} style={{ fontSize: "15px" }} />
              ) : (
                <cur.meta.icon style={{ fontSize: "13px" }} />
              )),
            children: cur.children && children,
            label: cur.meta.title,
          });
        }
        return pre;
      }, []);
  };
  // 获取当前展开的item
  const GetRouteOpen = () => {
    const current = GetRouteByPath(location.pathname, router);
    const pid = GetRoutePidByID(current ? current.meta.id : 1000, router);
    if (pid) setOpenKeys([pid.meta.id.toString()]);
    if (current) setSelectedKeys([current.meta.id.toString()]);
  };
  // 菜单初始化
  const initMenu = async () => {
    setItems(GetRouteMenu(menuList, router));
    GetRouteOpen();
  };

  // 点击菜单时的点击事件
  const onSelect: MenuProps["onSelect"] = (keys) => {
    const current = GetRouteByID(Number(keys.key), router);
    const pid = GetRoutePidByID(Number(keys.key), router);
    setSelectedKeys([keys.key]);
    if (pid) setOpenKeys([pid.meta.id.toString()]);
    if (current) navigate(current.path);
  };

  // 展开sub时的点击事件
  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    if (!status) setState(true); // 打开菜单恢复状态
    if (keys.length) {
      const current = keys.pop();
      if (current) setOpenKeys([current]);
    } else {
      setOpenKeys([]);
    }
  };

  // 当菜单栏数据加载完成后才执行展开侧边栏
  useEffect(() => {
    console.log(1);
    initMenu();
  }, []);
  useEffect(() => {
    // 监听是否为点击了个人中心，点击了就将下拉关闭
    if (!status) {
      setOpenKeys([]);
      setSelectedKeys([]);
    }
  }, [status]);

  useEffect(() => {
    console.log(2);
    initMenu();
  }, [menuList]);
  return (
    <Sider
      style={{ paddingTop: "50px" }}
      collapsible
      collapsed={collapsedActive}
      theme="dark"
      width={180}
      trigger={null}
    >
      <Menu
        theme="dark"
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        onSelect={onSelect}
        onOpenChange={onOpenChange}
        mode="inline"
        items={items}
      />
      {/* <div className="sider-collapsed" onClick={() => setCollapsed(!collapsed)}> */}
      {/*  <Button */}
      {/*    className="collapsed-icon" */}
      {/*    type="link" */}
      {/*    icon={collapsed ? <RightOutlined /> : <LeftOutlined />} */}
      {/*  /> */}
      {/* </div> */}
    </Sider>
  );
}
