import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Button, message } from "antd";
import { CommonAPI } from "../../../../../api";
import "./index.scss";

export interface InfoWx {
  wxName?: string;
  headimgurl?: string;
}
export function BindWeChat(props: any) {
  // 引入“微信内嵌二维码”脚本
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = false;
  script.src = "http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
  document.head.appendChild(script);
  const { location } = props;

  const [wxInfo, setWxInfo] = useState<InfoWx>({});
  const navigate = useNavigate();
  const r = () => {
    const redirect = "https://tmj.mmcuav.cn/crm/wxLogin/bind";
    // const customeStyle =
    //   "data:text/css;base64,Lnd4X3FyY29kZSBpZnJhbWUgeyB3aWR0aDogMTYwcHg7IGhlaWdodDogMTYwcHg7IG1hcmdpbjogMDsgcGFkZGluZzogMDsgfQ0KLmxvZ2luUGFuZWwgeyBtYXJnaW46MDsgcGFkZGluZzogMDsgfQ0KLmxvZ2luUGFuZWwgLnRpdGxlLCAubG9naW5QYW5lbCAuaW5mbyB7IGRpc3BsYXk6IG5vbmU7IH0NCi5pbXBvd2VyQm94IC5xcmNvZGUgeyBtYXJnaW46IDA7IHdpZHRoOiAxNjBweDsgYm9yZGVyOiBub25lOyB9";
    const Login = new window.WxLogin({
      self_redirect: false,
      id: "wx_login_container",
      appid: "wx0af16d5fb9ae561c", // 微信开放平台网站应用appid
      scope: "snsapi_login",
      redirect_uri: encodeURI(redirect), // 设置扫码成功后回调页面
      state: "onlineEdu",
      app_secret: "6ba6117ac22c545661eb7f8d9326138d",
      response_type: "code",
      style: "white",
      // href: customeStyle, // location.origin + "/css/wx_qrcode.css", //自定义微信二维码样式文件
    });

    // 设置iframe标签可以进行跨域跳转
    const qrcodeBox = document.getElementById(
      "wx_login_container"
    ) as HTMLElement;
    const iframes = qrcodeBox.getElementsByTagName("iframe");
    if (iframes.length) {
      const ifr = iframes[0];
      ifr.setAttribute(
        "sandbox",
        "allow-scripts allow-top-navigation allow-same-origin"
      );
      ifr.setAttribute("id", "iframeSon");
    }
  };
  const indexFilter = (url: string) => {
    // 新对象
    const obj: any = {};
    // const url = "http://localhost:8080/?id=2&type=1&age=20"; // 模拟url 地址
    const strList = url.split("&");
    strList.forEach((item: any) => {
      // eslint-disable-next-line prefer-destructuring
      obj[item.split("=")[0]] = item.split("=")[1];
    });
    return obj;
  };
  const uploadFame = async () => {
    const res = await CommonAPI.wxLoginUntie();
    if (res.code === 200) {
      message.success("解绑成功");
      navigate("/personalCenter/myInformation");
      const res1 = await CommonAPI.getWxList();
      setWxInfo(res1.data);
      setTimeout(() => {
        r();
      }, 300);
    }
  };
  const getWxList = async () => {
    const res = await CommonAPI.getWxList();
    setWxInfo(res.data);
    const Info = JSON.parse(JSON.stringify(res.data));
    if (!Info.wxName && location.search) {
      const cate = indexFilter(location.search.replace("?", ""));
      if (cate) {
        const res1 = await CommonAPI.wxLoginCallBack(cate);
        if (res1.code === 200) {
          message.success("绑定成功");
          navigate("/personalCenter/myInformation");
          await getWxList();
        }
      }
    } else {
      setTimeout(() => {
        r();
      }, 300);
    }
  };
  // 加载微信二维码
  useEffect(() => {
    (async () => {
      await getWxList();
    })();
  }, []);

  return (
    <div>
      {wxInfo.wxName ? (
        <div className="wxBox">
          <div>绑定状态: 已绑定</div>
          <div className="wxImg">
            <img src={wxInfo.headimgurl} alt="" />
          </div>
          <div>微信名称: {wxInfo.wxName}</div>
          <Button style={{ width: 100 }} onClick={() => uploadFame()}>
            解绑
          </Button>
        </div>
      ) : null}
      {!wxInfo.wxName && (
        <>
          <div id="wx_login_container">
            <div className="iframe" />
          </div>
          <span>提示：</span>
          <span style={{ color: "#ff8b18" }}>
            微信扫码后，请勾选重定向允许浏览器访问！
          </span>
        </>
      )}
    </div>
  );
}
