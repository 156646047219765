/*
 * @Author: kai 17660422924@163.com
 * @Date: 2024-01-04 16:14:22
 * @LastEditors: kai 17660422924@163.com
 * @LastEditTime: 2024-04-18 14:09:13
 * @FilePath: \mmc-tianmu-admin\src\api\modules\flightManagement\flightManagementAPI.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Axios from "../../request";
import {
  getMarkWaterWarringInfoType,
  thresholdsType,
  updateMarkWaterWarringInfoType,
  updateThresholdsType,
} from "./interface";
// import { ResponseListTypeDms } from "../../interface";
// import { UavPageProps, BrandAdd } from "./interface";
// import {
//   ResType,
//   ResSortieFlightType,
//   FlightRecordsType,
//   ImageRecordsType,
//   ImageType,
//   VideoType,
//   PointType,
//   MapType,
// } from "../../../pages/deviceCenter/deviceManage/interface";

export class flightManagement {
  // 航线

  // 航线列表
  static flightLineList: any = (params: any) =>
    Axios.get("/dms/route/page", { params });

  // 航线删除
  static flightLineDelete = (id: number) =>
    Axios.delete(`/dms/route/delete/${id}`);

  // 航线详情
  static flightLineDetails = (params: any) =>
    Axios.get("/dms/route/id", { params });

  // 空域

  // 空域列表
  static flightAirspaceList: any = (params: any) =>
    Axios.get("/dms/airspace/page", { params });

  // 空域删除
  static flightAirspaceDelete = (id: number) =>
    Axios.delete(`/dms/airspace/delete/${id}`);

  static flightAirspaceAdd = (data: any) =>
    Axios.post("/dms/airspace/add", { data });

  // 告警颜色数值-分页
  static alertInformationPost = () => Axios.post("/dms/alertInformation/page");

  // 告警颜色数值-修改
  static alertInformationUpdate: any = (data: any) =>
    Axios.put("/dms/alertInformation/update", data);

  // 空域编辑
  static flightAirspaceEdit = (data: any) =>
    Axios.put("/dms/airspace/update", { data });

  //     水位预警-阈值
  static getMarkWaterWarringInfo: getMarkWaterWarringInfoType = () =>
    Axios.get("/crm/dimensionMark/getMarkWaterWarringInfo");

  //   水位预警-阈值更新
  static updateMarkWaterWarringInfo: updateMarkWaterWarringInfoType = (data) =>
    Axios.put("/crm/dimensionMark/updateMarkWaterWarringInfo", data);

  //   气体预警-阈值
  static getThresholds: thresholdsType = (params) =>
    Axios.get("/uas/alertThresholds/thresholds", { params });

  //   气体预警-阈值更新
  static updateThresholds: updateThresholdsType = (data) =>
    Axios.post("/uas/alertThresholds/thresholds", data);
}
