import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { SuperManageAPI } from "../../../../../../../api";
import {
  msgResponseListTypeOther,
  PaginationPropsOther,
} from "../../../../../../../api/interface";
import { getAddServiceInfoListType } from "../../../../../../../api/modules/superManage/interface";

// 流量和存储服务列表返回类型
type addServiceInfoListResType =
  msgResponseListTypeOther<getAddServiceInfoListType>;
const FlowStorageService = function () {
  const tableColumns: ColumnsType<any> = [
    {
      title: "年月份",
      align: "center",
      dataIndex: "useDate",
    },
    {
      title: "地图服务调用次数（万次）",
      align: "center",
      dataIndex: "mapUseSize",
      render: (text: number, record) => `${text}/${record.mapAllSize}`,
    },
    {
      title: "地图用量状态",
      align: "center",
      dataIndex: "mapType",
      render: (text: number) => (
        <span style={{ color: text === 1 ? "green" : "red" }}>
          {text === 1 ? "正常" : "超量"}
        </span>
      ),
    },
    {
      title: "实时视频播放时长（分钟）",
      align: "center",
      dataIndex: "liveUseTime",
      render: (text: number, record) => `${text}/${record.liveAllTime}`,
    },
    {
      title: "视频用量状态",
      align: "center",
      dataIndex: "liveType",
      render: (text: number) => (
        <span style={{ color: text === 1 ? "green" : "red" }}>
          {text === 1 ? "正常" : "超量"}
        </span>
      ),
    },
    {
      title: "文件存储空间（G）",
      align: "center",
      dataIndex: "fileUseSize",
      render: (text: number, record) => `${text}/${record.fileAllSize}`,
    },
    {
      title: "存储空间状态",
      align: "center",
      dataIndex: "dataType",
      render: (text: number) => (
        <span style={{ color: text === 1 ? "green" : "red" }}>
          {text === 1 ? "正常" : "超量"}
        </span>
      ),
    },
  ];

  const [tableData, setTableData] = useState<addServiceInfoListResType>([]);
  const [pagination, setPagination] = useState<
    PaginationPropsOther & { totalCount: number }
  >({
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
  });
  // 流量和存储服务-列表
  const getAddServiceInfoList = () => {
    SuperManageAPI.getAddServiceInfoList({
      pageSize: pagination.pageSize,
      currentPage: pagination.currentPage,
    }).then((res) => {
      setTableData(res.data.items || []);
      pagination.totalCount = res.data.totalCount;
      setPagination({ ...pagination });
    });
  };
  // 分页
  const paginationChange = (pageNo: number, pageSize: number) => {
    pagination.currentPage = pageNo;
    pagination.pageSize = pageSize;
    getAddServiceInfoList();
  };

  useEffect(() => {
    getAddServiceInfoList();
  }, []);
  return (
    <div className="flow-storage-service">
      <Table
        columns={tableColumns}
        dataSource={tableData}
        pagination={{
          total: pagination.totalCount,
          pageSize: pagination.pageSize,
          current: pagination.currentPage,
          showSizeChanger: true,
          showQuickJumper: true,
          onChange: (page: number, pageSize: number) =>
            paginationChange(page, pageSize),
          showTotal: (total, range) =>
            `当前 ${range[0]}-${range[1]} 条记录 / 共 ${total} 条数据`,
        }}
      />
    </div>
  );
};
export default FlowStorageService;
