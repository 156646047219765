import { Input, Modal } from "antd";
import React, { FC, useState } from "react";
import "./index.scss";
import { modalProps } from "../../../../../assets/common/interface/modal";
import searchIcon from "../../../../../assets/images/login/search_icon.png";
import navigateIcon from "../../../../../assets/images/login/navigate_icon.png";
import {
  applicationEntity,
  projectEntity,
} from "../../../../../api/modules/superManage/interface";

interface selfProps {
  toProjectPath: (id: number) => void;
  projectList: (Pick<projectEntity, "name" | "id" | "appId"> &
    Pick<applicationEntity, "url" | "manageUrl" | "type">)[];
  inputChange: (val: string) => void;
}

function ProjectModal({
  visible,
  handleOk,
  handleCancel,
  toProjectPath,
  projectList,
  inputChange,
}: modalProps & selfProps) {
  // 跳转对应项目
  const toProjectPathEvent = (id: number) => {
    toProjectPath(id);
  };
  // input event
  const inputChangeEvent = (e: any) => {
    inputChange(e.target.value);
  };
  return (
    <Modal
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      title="选择项目"
    >
      <div className="project-select-content">
        <div className="search-wrap">
          <Input
            placeholder="请输入关键字"
            prefix={
              <img
                src={searchIcon}
                alt=""
                style={{ width: "24px", height: "24px" }}
              />
            }
            onChange={inputChangeEvent}
          />
        </div>
        <div className="project-list-wrap">
          {projectList.map((v, index: number) => (
            <div className="project-item" key={index}>
              <div className="project-name">{v.name}</div>
              <div className="navigate-icon">
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <img
                  src={navigateIcon}
                  alt=""
                  onClick={() => toProjectPathEvent(v.id)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default ProjectModal;
