import React, { useEffect, useState, useReducer } from "react";
import "./index.scss";
import { Form, Select, Space, message, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";

import { FormList } from "./formList";
import { CommonAPI, SuperManageAPI } from "../../../../../api";

export function AssociatedLogin(props: any) {
  const { userId } = props;
  const [options, setOptions] = useState<any[]>([]);
  const [checked, setChecked] = useState<any[]>([]);
  const [bind, setBind] = useState<any[]>([]);
  const [bindList, setBindList] = useState<any[]>([]);
  const { userInfo } = useSelector((state: any) => state.UserInfo);
  interface ResType {
    projectId: number;
    projectName: string;
    lng?: number;
    lat?: number;
    appid: number;
    appName: string;
    type?: number;
    icon?: null;
    url?: string;
    manageurl?: string;
    versions?: number | string;
    phone?: number | string;
    email?: string;
    remark: null;
    contacts: null;
    id?: number;
    username?: string;
    password?: string;
    appId?: number;
    userId?: number;
  }
  // 保存初始化数据
  const [optionsAll, setOptionsAll] = useState<any[]>([]);
  const getOptions = async (formApp?: number) => {
    const res = await CommonAPI.getApplication({
      userId: userInfo.id,
      roleId: userInfo.roleId,
    });
    const arr: any = res.data;
    // 保存初始化 数据
    setOptionsAll(arr);
    // 外部应用平台下拉框需要去重
    const uniqueData = arr.reduce(function (acc: any, current: any) {
      const existing = acc.find(function (item: any) {
        return item.appid === current.appid;
      });

      if (!existing) {
        acc.push(current);
      }

      return acc;
    }, []);
    setOptions(uniqueData);
    if (formApp) {
      setChecked((prevState: any) => {
        const arrState = JSON.parse(JSON.stringify(prevState));
        arrState.forEach((item: any, index: any) => {
          if (item.appId === formApp) {
            arrState[index] = Array(res.data)[0].find(
              (items: any) => items.id === formApp
            );
          }
        });
        return arrState;
      });
    }
  };
  const getBindList = async (formApp?: any) => {
    const formData = new FormData();
    formData.append("userId", userInfo.id);
    formData.append("roleId", userInfo.roleId);
    const res = await SuperManageAPI.getBindList(formData);
    const resBindList: any = res.data;
    if (resBindList && resBindList.length > 0) {
      const resBindListData = await mergeArraysById(resBindList);
      setChecked(resBindListData);
    }
    setBind(res.data);
  };
  const mergeArraysById = async (array2: any[]): Promise<any[]> => {
    const res = await CommonAPI.getApplication({
      userId: userInfo.id,
      roleId: userInfo.roleId,
    });
    const array1: any = res.data;
    const mergedArray: any[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < array1.length; i++) {
      const item1 = array1[i];
      const exists = mergedArray.find((k: ResType) => {
        return k.projectName === item1.projectName;
      });

      if (!exists) {
        let shouldAdd = true;
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < array2.length; j++) {
          const item2 = array2[j];
          if (
            Number(item1.appid) === Number(item2.appId) ||
            Number(item1.appid) === Number(item2.appid)
          ) {
            if (item2.projectName === item1.projectName) {
              mergedArray.push({ ...item1, ...item2 });
              shouldAdd = false; // 不需要再添加 item1
            }
          }
        }

        if (shouldAdd) {
          mergedArray.push(item1);
        }
      }
    }
    return mergedArray;
  };
  useEffect(() => {
    setBindList(
      Array.from(new Set(checked.map((item: ResType) => item.appName)))
    );
  }, [checked]);

  useEffect(() => {
    (async () => {
      await getOptions();
      await getBindList();
    })();
  }, []);
  // 选择应用平台
  const handleChange = async (value: string[], v: any) => {
    if (v.length > 0) {
      const formData = new FormData();
      formData.append("userId", userInfo.id);
      formData.append("roleId", userInfo.roleId);
      const res = await SuperManageAPI.getBindList(formData);
      setBind(res.data);
      const resBindListData = await mergeArraysById(v);
      if (bind.length > 0) {
        const resBind = await mergeArraysById(bind);
        setChecked(resBind);
      } else {
        setChecked(resBindListData);
      }
    } else {
      setChecked([]);
    }
  };
  const resetbindFn = async () => {
    if (userId) {
      const res = await SuperManageAPI.resetbind(Number(userId));
      if (res.code === 200) {
        message.success(res.msg);
        await getOptions();
        await getBindList();
      } else {
        message.error(res.msg);
      }
    }
  };
  // 关联绑定
  const onFormFinish = async (item: any, info: any) => {
    // 解除绑定
    if (item.id) {
      const form = new FormData();
      form.append("id", item.id);
      const res = await SuperManageAPI.unBindTerrace(form);
      if (res.code === 200) {
        message.success("解除绑定成功!");
        const checkedCopy = JSON.parse(JSON.stringify(checked));
        const newChecked = checkedCopy.map((data: ResType) => {
          if (
            data.appid === item.appid &&
            data.projectName === item.projectName
          ) {
            delete data.id;
            delete data.username;
            delete data.password;
            delete data.appId;
            return data;
          }
          return data;
        });
        setChecked(newChecked);
      }
    } else {
      const formID = Number(item.appid);
      const { projectId } = checked.find(
        (items: any) =>
          items.appid === formID && items.projectName === item.projectName
      );
      const res = await SuperManageAPI.BindTerrace({
        appId: formID,
        username: info.username,
        projectId,
        password: btoa(info.password),
        userId: userInfo.id,
        url: item.url,
      });
      if (res.code === 200) {
        message.success(res.msg);
        setChecked([]);
        await getBindList(item);
      } else {
        message.error("关联绑定失败，请重新输入并绑定！").then();
      }
    }
  };
  return (
    <div className="associated-box">
      <div className="box-item">
        <div className="item-label">外部应用平台:</div>
        <div className="item-value">
          <Space style={{ width: "100%" }} direction="vertical">
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="请选择"
              onChange={handleChange}
              options={options}
              value={bindList}
              fieldNames={{ label: "appName", value: "appName" }}
            />
          </Space>
        </div>
        <div>
          <Button
            disabled={userInfo.roleType === 2}
            onClick={resetbindFn}
            style={{ marginLeft: "10px" }}
          >
            一键与管理员同配置
          </Button>
        </div>
      </div>
      <Form.Provider>
        {checked?.map((item: any) => (
          <div key={item.password ? item.id : item.appId} className="box-item">
            <div className="item-label">
              {`${item.appName || item.name}/`}
              <span>
                <span className="projectName">{`${item.projectName}`}</span>:
              </span>
            </div>
            <div className="item-value">
              <FormList onFinish={onFormFinish} item={item} />
            </div>
          </div>
        ))}
      </Form.Provider>
    </div>
  );
}
