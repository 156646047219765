// 过滤对象属性
export const filterObjAttr = (obj: any, key: string[]) => {
  return Object.keys(obj).reduce((pre: any, cur: string) => {
    if (!key.includes(cur)) {
      pre[cur] = obj[cur];
    }
    return pre;
  }, {});
};
// 限制input空格
export const inputEvent = (e: any) => {
  e.target.value = e.target.value.replace(/\s+/g, "");
};
// 限制输入非正整数
export const inputNumberEvent = (e: any) => {
  e.target.value = e.target.value.replace(/[^0-9]/g, "");
};
