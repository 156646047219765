import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/style/index.scss";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import "dayjs/locale/zh-cn";
import zhCN from "antd/locale/zh_CN";
import App from "./App";
import { renderEmpty, themeConfig } from "./assets/theme";
import { store } from "./store";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement as HTMLElement);

root.render(
  // <React.StrictMode>
  <ConfigProvider locale={zhCN} theme={themeConfig} renderEmpty={renderEmpty}>
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>
  // </React.StrictMode>
);
