import Axios from "../../request";

import {
  callBack,
  mapType,
  deleteType,
  addType,
  dowType,
  monitorAddType,
} from "./interface";

export class BasicMapAPI {
  // 断面检测
  static monitorPage(params: mapType) {
    return Axios.get("/dms/monitor/page", { params });
  }

  // 断面检测
  static monitor(id: number) {
    return Axios.get(`/dms/monitor/${id}`);
  }

  // 断面检测-历史记录
  static monitorHistory(params: { id: number; sortieName: string }) {
    return Axios.get("/dms/monitor/history", { params });
  }

  // 新建断面检测
  static monitorAdd(params: monitorAddType) {
    return Axios.post("/dms/monitor/add", { ...params });
  }

  // 图层数据类型
  static getMapTypeList() {
    return Axios.get("/crm/dimensionMap/getMapType");
  }

  // 下载标注区域统计标注报告
  static dowMarkChartReport(params: dowType) {
    return Axios.get("/crm/dimensionMark/dowMarkChartReport", {
      params,
      responseType: "blob",
    });
  }

  // 下载
  static monitorExcel() {
    return Axios.get("/dms/monitor/excel", {
      responseType: "blob",
    });
  }

  // 区域统计
  static getMarkChartReportList(params: mapType) {
    return Axios.get("/crm/dimensionMark/getMarkChartReportList", { params });
  }

  static dimensionMark(params: mapType) {
    return Axios.get("/crm/dimensionMark/pageAll", { params });
  }

  // 获取所有标签
  static getPageLabels(params: mapType) {
    return Axios.get("/crm/dimensionLabel/getPageLabels", { params });
  }

  //
  static monitorDelete(params: Partial<deleteType>) {
    return Axios({
      method: "delete",
      url: `/dms/monitor/delete/${params.id}`,
      // params,
    });
  }

  // 编辑图层
  static monitorUpdate(params: monitorAddType) {
    return Axios.put("/dms/monitor/update", { ...params });
  }

  // 删除标签
  static delLabbel(params: Partial<deleteType>) {
    return Axios({
      method: "delete",
      url: "/crm/dimensionLabel/delLabbel",
      params,
    });
  }

  // 图层数据
  static getMapList(params: mapType) {
    return Axios({
      method: "get",
      url: "/crm/dimensionMap/pageAll",
      params,
    });
  }

  // 新建图层
  static postMap(params: Partial<addType>) {
    return Axios.post("/crm/dimensionMap/addMap", { ...params });
  }

  // 编辑图层
  static putMap(params: Partial<addType>) {
    return Axios.put("/crm/dimensionMap/updateMap", { ...params });
  }

  // 删除标注
  static delMark(params: Partial<deleteType>) {
    return Axios({
      method: "delete",
      url: "/crm/dimensionMark/delMark",
      params,
    });
  }

  // 删除标注区域统计标注报告
  static delChartReport(params: Partial<deleteType>) {
    return Axios({
      method: "delete",
      url: "/crm/dimensionMark/delChartReport",
      params,
    });
  }

  // 删除图层
  static deleteMap(params: Partial<deleteType>) {
    return Axios({
      method: "delete",
      url: "/crm/dimensionMap/delMap",
      params,
    });
  }

  // 修改图层状态禁用启用
  static getStatus(params: Partial<mapType>) {
    return Axios.put("/crm/dimensionMap/updateMapStatus", { ...params });
  }
}
