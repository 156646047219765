import { useRoutes, useLocation, useNavigate } from "react-router-dom";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { message, Skeleton } from "antd";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import { whiteRouter, router } from "./index";
import {
  isRoute,
  filterRouter,
  GetRouteIDList,
  getLeafRoute,
} from "../utils/router";
import { ResponseType } from "../api/interface";
import { CommonAPI } from "../api";
import {
  REMOVE_MENU,
  REMOVE_MENU_ID,
  SET_MENU,
  SET_MENU_ID,
} from "../store/module/menu";

function LazyCom({ element }: { element: () => Promise<any> }) {
  const Element = lazy(element);
  return (
    <Suspense fallback={<Skeleton active />}>
      <Element />
    </Suspense>
  );
}

function PrivateRouter() {
  // redux
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const menuList = useSelector((state: any) => state.Menu.menuList);
  const menuId = useSelector((state: any) => state.Menu.menuId);
  const [route, setRoute] = useState<any>([]);
  const convertRouter = (routerList: any) => {
    return routerList.reduce((pre: any, cur: any) => {
      const obj = { ...cur };
      if (obj.children) {
        obj.children = convertRouter(obj.children);
      } else {
        obj.element = <LazyCom element={obj.element} />;
      }
      pre.push(obj);
      return pre;
    }, []);
  };
  const beforeEach = (path: string) => {
    const token = Cookies.get("TIANMU_TOKEN");
    if (token && menuList && menuList.length !== 0) {
      if (path === "/" || path === "/login") {
        navigate(getLeafRoute(GetRouteIDList(menuList), router)[0].path);
      }
    } else {
      navigate("/login", { replace: true });
    }
    if (
      !isRoute(
        [...filterRouter(router, GetRouteIDList(menuList)), ...whiteRouter],
        path
      )
    ) {
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    // 获取URL中的查询参数
    const searchParams = new URLSearchParams(window.location.search);
    // 获取token和userInfo参数的值
    const t = searchParams.get("t_");
    const userInfo = searchParams.get("userInfo");
    // 使用获取到的参数值进行进一步操作
    const init = async () => {
      if (t && userInfo) {
        console.log(1111);
        if (Cookies.get("TIANMU_TOKEN") && t) {
          dispatch(REMOVE_MENU_ID());
        }
        window.localStorage.setItem("TMJ-ADMIN-USER-INFO", userInfo);
        Cookies.set("TIANMU_TOKEN", t);
        if (JSON.parse(userInfo).roleType === 1) {
          const res: ResponseType<any[]> =
            await CommonAPI.getManagePermission();
          if (res.data && res.data.length !== 0) {
            message.success("登录成功");
            dispatch(SET_MENU(res.data));
            localStorage.setItem(
              "TMJ-ADMIN-MENU-LIST",
              JSON.stringify(res.data)
            );
            const routeList = getLeafRoute(GetRouteIDList(res.data), router);
            navigate(routeList[0].path);
            window.location.href = `${window.location.origin}`;
          } else {
            // message.info("请先配置权限！");
            Cookies.remove("TIANMU_TOKEN");
            dispatch(REMOVE_MENU());
            dispatch(REMOVE_MENU_ID());
          }
        } else {
          const res = await CommonAPI.getProjectListByUser();
          if (res.code === 200) {
            localStorage.setItem(
              "TMJ-ADMIN-MENU-ID",
              Array(res.data)[0].filter((item: any) => item.type === "1")[0].id
            );
            dispatch(
              SET_MENU_ID(
                Array(res.data)[0].filter((item: any) => item.type === "1")[0]
                  .id
              )
            );
            window.location.href = `${window.location.origin}`;
          }
        }
      }
    };
    init();
    // window.onmessage = async (e: MessageEvent<string>) => {
    //   const flag = process.env.NODE_ENV === "production";
    //   // 本地调试,本地不进行测试此功能就关闭，否则可能引起热更新错误
    //   // if (e.origin.includes("localhost")) flag = true;
    //   if (flag && e.data) {
    //     if (e.data.constructor !== String) return;
    //     const data = JSON.parse(e.data);
    //     // if (Cookies.get("TIANMU_TOKEN") && data.token) {
    //     //   Cookies.remove("TIANMU_TOKEN");
    //     //   dispatch(REMOVE_MENU());
    //     //   dispatch(REMOVE_MENU_ID());
    //     //   // window.location.href = `${window.location.origin}/#/login`;
    //     // }
    //     if (Cookies.get("TIANMU_TOKEN") && data.token) {
    //       dispatch(REMOVE_MENU_ID());
    //     }
    //     e.source?.postMessage("success", { targetOrigin: e.origin });
    //     window.localStorage.setItem("TMJ-ADMIN-USER-INFO", data.userInfo);
    //     Cookies.set("TIANMU_TOKEN", data.token);
    //     if (JSON.parse(data.userInfo).roleType === 1) {
    //       const res: ResponseType<any[]> =
    //         await CommonAPI.getManagePermission();
    //       if (res.data && res.data.length !== 0) {
    //         message.success("登录成功");
    //         dispatch(SET_MENU(res.data));
    //         localStorage.setItem(
    //           "TMJ-ADMIN-MENU-LIST",
    //           JSON.stringify(res.data)
    //         );
    //         const routeList = getLeafRoute(GetRouteIDList(res.data), router);
    //         navigate(routeList[0].path);
    //         window.location.href = `${window.location.origin}`;
    //       } else {
    //         // message.info("请先配置权限！");
    //         Cookies.remove("TIANMU_TOKEN");
    //         dispatch(REMOVE_MENU());
    //         dispatch(REMOVE_MENU_ID());
    //       }
    //     } else {
    //       const res = await CommonAPI.getProjectListByUser();
    //       if (res.code === 200) {
    //         localStorage.setItem(
    //           "TMJ-ADMIN-MENU-ID",
    //           Array(res.data)[0].filter((item: any) => item.type === "1")[0].id
    //         );
    //         dispatch(
    //           SET_MENU_ID(
    //             Array(res.data)[0].filter((item: any) => item.type === "1")[0]
    //               .id
    //           )
    //         );
    //         window.location.href = `${window.location.origin}`;
    //       }
    //     }
    //   }
    // };
    setRoute(convertRouter(router));
  }, []);
  useEffect(() => {
    beforeEach(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    (async () => {
      const itemId = localStorage.getItem("TMJ-ADMIN-MENU-ID");
      if (itemId) {
        const id = JSON.parse(itemId);
        if (id) {
          const res: ResponseType<any[]> = await CommonAPI.getManagePermission(
            id
          );
          if (res.data && res.data.length !== 0) {
            dispatch(SET_MENU(res.data));
            localStorage.setItem(
              "TMJ-ADMIN-MENU-LIST",
              JSON.stringify(res.data)
            );
            const routeList = getLeafRoute(GetRouteIDList(res.data), router);
            navigate(routeList[0].path);
          } else {
            message.info("请先配置权限！");
          }
        }
      }
    })();
  }, [menuId]);

  return useRoutes([...whiteRouter, ...route]);
}

export default PrivateRouter;
