import { Empty, theme } from "antd";
import React from "react";

export const themeConfig = {
  algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
  token: {
    // fontSize: 12,
  },
  components: {
    Table: {
      colorBgContainer: "#10151A",
    },
    Input: {
      colorBgContainer: "#020310",
      colorTextPlaceholder: "rgba(255, 255, 255, 0.5)",
      colorBorder: "#316B82",
    },
    Select: {
      colorBgContainer: "#020310",
      colorTextPlaceholder: "rgba(255, 255, 255, 0.5)",
      colorBorder: "#316B82",
      colorBgElevated: "#2C313B",
    },
    TreeSelect: {
      colorBgContainer: "#020310",
      colorTextPlaceholder: "rgba(255, 255, 255, 0.5)",
      colorBorder: "#316B82",
      colorBgElevated: "#2C313B",
    },
    DatePicker: {
      colorBgContainer: "#020310",
      colorTextPlaceholder: "rgba(255, 255, 255, 0.5)",
      colorBorder: "#316B82",
      colorBgElevated: "#2C313B",
      controlItemBgActive: "#1668dc",
    },
    Button: {
      colorBorder: "#316B82",
      colorBgContainer: "#3A4252",
    },
    Tabs: {
      colorBgContainer: "#12181E",
    },
    Modal: {
      colorBgElevated: "#2C313B",
    },
    Dropdown: {
      colorBgElevated: "#2C313B",
    },
    Drawer: {
      colorBgElevated: "#2C313B",
    },
    Menu: {
      fontSize: 13,
    },
  },
};
// 空状态定制
export const renderEmpty = () => (
  <Empty description="暂无数据" image={Empty.PRESENTED_IMAGE_SIMPLE} />
);
