import React from "react";
import { HashRouter } from "react-router-dom";
import "./assets/style/App.scss";
import PrivateRoute from "./router/privateRouter";

export default function App() {
  return (
    <HashRouter>
      <PrivateRoute />
    </HashRouter>
  );
}
