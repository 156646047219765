import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import SelfTabs, { tabItemEntity } from "../../../components/selfTabs";
import "./index.scss";
import { Information } from "./comp/information";
import { AssociatedLogin } from "./comp/associatedLogin";
import { ChangePassword } from "./comp/changePassword";
import { BindWeChat } from "./comp/bindWeChat";
import AddService from "./comp/addService";
import { CommonAPI, SuperManageAPI } from "../../../api";
// eslint-disable-next-line import/order
import { useSelector } from "react-redux";
import { TableType } from "./interface";

export function MyInformation() {
  const location = useLocation();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: any) => state.UserInfo);
  const [infoUser, setInfoUser] = useState<TableType>({
    userAccount: "",
    userName: "",
    wxName: "",
    uid: 1,
    roleType: "",
    tenantName: "",
    phone: 2,
    email: "",
  });
  const getUserInfo = async () => {
    const res = await CommonAPI.getPersonalCenter(userInfo.id);
    setInfoUser(res.data);
  };
  useEffect(() => {
    (async () => {
      await getUserInfo();
    })();
    // if (location.state?.id) {
    //   navigate("/personalCenter/myInformation", {
    //     state: {
    //       id: location.state?.id,
    //     },
    //   });
    // } else {
    //   navigate("/personalCenter/myInformation", {
    //     state: {
    //       id: 1,
    //     },
    //   });
    // }
    if (location.search) {
      setCurrentTabIndex(4);
    }
    // setCurrentTabIndex(location.state?.id || 1);
  }, []);

  const [tabList] = useState<tabItemEntity[]>([
    {
      name: "我的信息",
    },
    {
      name: "关联登录",
    },
    {
      name: "修改密码",
    },
    {
      name: "绑定微信",
    },
    {
      name: "增值服务",
    },
  ]);
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(1);
  const renderElement = () => {
    if (currentTabIndex === 1) {
      return <Information infoUser={infoUser} />;
    }
    if (currentTabIndex === 2) {
      return <AssociatedLogin userId={infoUser.uid} />;
    }
    if (currentTabIndex === 3) {
      return <ChangePassword infoUser={infoUser} />;
    }
    if (currentTabIndex === 4) {
      return <BindWeChat location={location} />;
    }
    return <AddService />;
  };
  const tabItemClick = (index: number) => {
    // navigate("/personalCenter/myInformation", {
    //   state: {
    //     id: index,
    //   },
    // });
    setCurrentTabIndex(index);
  };
  return (
    <div className="component-manage">
      <SelfTabs
        tabList={tabList}
        currentTabIndex={currentTabIndex}
        tabItemClick={tabItemClick}
        tabBgColor="#0C121A"
        activeTabItemBgColor="#1A232C"
        showLine={false}
      >
        {renderElement()}
      </SelfTabs>
    </div>
  );
}
