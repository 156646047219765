import React, { useEffect, useState } from "react";
import { Header } from "antd/es/layout/layout";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import {
  Button,
  Dropdown,
  MenuProps,
  Modal,
  Select,
  Space,
  message,
} from "antd";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import Logo from "../../../assets/images/login/favicon.png";
import {
  SET_COLLAPSE,
  REMOVE_MENU,
  SET_MENU_ID,
  REMOVE_MENU_ID,
} from "../../../store/module/menu";
import "./index.scss";
import { CommonAPI } from "../../../api";
import { InfoType } from "../../../pages/deviceCenter/productType/interface";
import { ResponseType } from "../../../api/interface";

const { REACT_APP_BASE_WEB_URL } = process.env;
interface propsType {
  ChangeOpen: (status: boolean) => void;
}

export function TitleView(props: propsType) {
  const { ChangeOpen } = props;
  const dispatch = useDispatch();
  const [infoForUser, setInfoForUser] = useState<InfoType>();
  const [isActive, setIsActive] = useState(false);
  const [Time, setTime] = useState<string>();
  const [countDown, setCountDown] = useState<NodeJS.Timeout>();
  const [userProjectName, setUserProjectName] = useState<string>("");
  // const [userValue, setUserValue] = useState<InfoType>();
  const [userProjectOption, setUserProjectOption] = useState([]);
  // 对话框
  const { confirm } = Modal;
  const { userInfo } = useSelector((state: any) => state.UserInfo);
  const clickForWindow = () => {
    window.sender = window.open(REACT_APP_BASE_WEB_URL); // url：B系统的地址
    const timer = setInterval(() => {
      window.sender.postMessage(
        JSON.stringify({
          token: Cookies.get("TIANMU_TOKEN"),
          userInfo: window.localStorage.getItem("TMJ-ADMIN-USER-INFO"),
        }),
        REACT_APP_BASE_WEB_URL
      ); // str:发送给B系统的参数
    }, 200);
    window.addEventListener(
      "message",
      (event: MessageEvent) => {
        if (event.data === "success") {
          clearInterval(timer);
        } else {
          console.log(event, "免密跳转登录失败！");
        }
      },
      false
    );
  };

  const getInfo = async () => {
    const res = await CommonAPI.getPersonalCenter(userInfo.id);
    if (res.code === 200) {
      const times = new Date(res.data.validityDate).getTime() - Date.now();
      getTimer(times);
      setInfoForUser(res.data);
      // const timeNumber = setInterval(() => {
      //   if (new Date(res.data.validityDate).getTime() - Date.now() < 0) {
      //     console.log("超时");
      //   }
      //   console.log("未超时");
      // }, 1000);
      // setCountDown(timeNumber);
      // setUserValue(res.data);
    }
  };
  const getTimer = (times: number) => {
    // console.log(times);
    const days = times / (1000 * 60 * 60 * 24);
    const hours = (times % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60);
    const minutes = (times % (1000 * 60 * 60)) / (1000 * 60);
    const seconds = (times % (1000 * 60)) / 1000;
    // console.log(
    //   `${days.toFixed(0)} 天 ${hours.toFixed(0)} 时 ${minutes.toFixed(
    //     0
    //   )} 分 ${seconds.toFixed(0)} 秒 `
    // );
    // return days.toFixed(0);
    if (days >= 1) {
      setTime(`${days.toFixed(0)}天`);
      if (days <= 7) {
        confirm({
          title: "提示",
          mask: false,
          content: `您的账号有效期还剩下${days.toFixed(0)}天${hours.toFixed(
            0
          )}小时${minutes.toFixed(0)}分${seconds.toFixed(
            0
          )}秒,为了不影响您的工作，请及时联系平台研发制造商进行续约`,
          wrapClassName: "effective",
          cancelText: "确定",
          onCancel() {
            console.log("信息确定");
          },
        });
      }
    } else if (days < 1 && hours >= 1) {
      setTime(`${hours.toFixed(0)}小时`);
      confirm({
        title: "提示",
        mask: false,
        content: `您的账号有效期还剩下${days.toFixed(0)}天${hours.toFixed(
          0
        )}小时${minutes.toFixed(0)}分${seconds.toFixed(
          0
        )}秒,为了不影响您的工作，请及时联系平台研发制造商进行续约`,
        wrapClassName: "effective",
        cancelText: "确定",
        onCancel() {
          console.log("信息确定");
        },
      });
    } else if (hours < 1 && minutes >= 1) {
      setTime(`${minutes.toFixed(0)}分钟`);
      confirm({
        title: "提示",
        mask: false,
        content: `您的账号有效期还剩下${days.toFixed(0)}天${hours.toFixed(
          0
        )}小时${minutes.toFixed(0)}分${seconds.toFixed(
          0
        )}秒,为了不影响您的工作，请及时联系平台研发制造商进行续约`,
        wrapClassName: "effective",
        cancelText: "确定",
        onCancel() {
          console.log("信息确定");
        },
      });
    } else if (minutes < 1 && seconds >= 1) {
      setTime(`${seconds.toFixed(0)}秒`);
      confirm({
        title: "提示",
        mask: false,
        content: `您的账号有效期还剩下${days.toFixed(0)}天${hours.toFixed(
          0
        )}小时${minutes.toFixed(0)}分${seconds.toFixed(
          0
        )}秒,为了不影响您的工作，请及时联系平台研发制造商进行续约`,
        wrapClassName: "effective",
        cancelText: "确定",
        onCancel() {
          console.log("信息确定");
        },
      });
    }
  };

  const getUserProject = async () => {
    const res = await CommonAPI.getProjectListByUser();
    setUserProjectOption(res.data);
    const Id = localStorage.getItem("TMJ-ADMIN-MENU-ID");
    if (Id) {
      const menuId = JSON.parse(Id);
      if (menuId)
        setUserProjectName(
          Array(res.data)[0].filter(
            (item: any) => Number(menuId) === item.id
          )[0]?.name
        );
    }
  };
  const handleChange = (value: string) => {
    if (
      (userProjectOption.filter((items: any) => value === items.id)[0] as any)
        .type === "2"
    ) {
      window.open(
        (userProjectOption.filter((items: any) => value === items.id)[0] as any)
          .url,
        "_blank"
      );
    } else {
      setUserProjectName(String(filterUserProject("name", value)));
    }
  };

  const filterUserProject = (
    status: string,
    value: number | string
  ): string | number => {
    if (status === "name") {
      return (
        userProjectOption.filter((item: any) => value === item.id)[0] as {
          name: string;
        }
      ).name;
    }
    return (
      userProjectOption.filter((item: any) => value === item.name)[0] as {
        id: number;
      }
    ).id;
  };

  const getMenuList = async (id: string) => {
    if (id) {
      const res: ResponseType<any[]> = await CommonAPI.getManagePermission(
        Number(id)
      );
      if (res.data && res.data.length !== 0) {
        localStorage.setItem("TMJ-ADMIN-MENU-LIST", JSON.stringify(res.data));
      }
    }
  };
  useEffect(() => {
    userProjectOption.forEach(async (item: any) => {
      try {
        if (userProjectName === item.name) {
          dispatch(SET_MENU_ID(item.id));
        } else {
          throw new Error("EndIterative");
        }
      } catch (e) {
        if ((e as Error).message !== "EndIterative") throw e;
      }
    });
  }, [userProjectName]);

  useEffect(() => {
    (async () => {
      await getInfo();
      await getUserProject();
      const id = localStorage.getItem("TMJ-ADMIN-MENU-ID");
      if (id) {
        if (JSON.parse(id)) {
          await getMenuList(JSON.parse(id));
        }
      }
    })();
    return () => {
      if (countDown) clearInterval(countDown);
    };
  }, []);
  // 右上角按钮
  const items: MenuProps["items"] = [
    {
      key: "0",
      label: (
        <Button type="link" style={{ color: "#ffffff" }}>
          有效期剩下{Time}
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          type="link"
          style={{ color: "#ffffff" }}
          onClick={() => {
            ChangeOpen(false);
            window.location.href = `${window.location.origin}/#/personalCenter/myInformation`;
          }}
        >
          个人中心
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          type="link"
          style={{ color: "#ffffff" }}
          // onClick={() => {
          //   ChangeOpen(false);
          //
          //   // window.location.href = `${REACT_APP_BASE_WEB_URL}`;
          // }}
          onClick={clickForWindow}
        >
          应用平台
        </Button>
      ),
    },
    {
      key: "1",
      label: (
        <Button
          type="link"
          style={{ color: "#ffffff" }}
          onClick={() => {
            confirm({
              title: "退出登录",
              content: "退出后未保存数据将会丢失，确定登出吗？",
              onOk() {
                window.location.href = `${window.location.origin}/#/login`;
                Cookies.remove("TIANMU_TOKEN");
                dispatch(REMOVE_MENU());
                dispatch(REMOVE_MENU_ID());
              },
            });
          }}
        >
          退出登录
        </Button>
      ),
    },
  ];
  // 菜单栏收起
  const setMenuCollapsed = () => {
    setIsActive(!isActive);
    dispatch(SET_COLLAPSE(!isActive));
  };
  return (
    <Header
      style={{
        background: "#2c313b",
        height: 40,
        padding: "0 10px",
        position: "relative",
      }}
    >
      <div className="layout-logo">
        <img className="logo-img" src={Logo} alt="云享飞" />
        <div className="logo-text">天目将后台管理</div>
        <div onClick={setMenuCollapsed}>
          <svg
            className={`hamburger ${isActive ? "is-active" : ""}`}
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
          >
            <path d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z" />
          </svg>
        </div>
      </div>
      <div className="title-user">
        {userInfo.roleType !== 1 ? (
          <div className="current-project">
            <div className="project-label">当前项目:</div>
            <Space wrap>
              <Select
                style={{ width: 100 }}
                onChange={handleChange}
                options={userProjectOption}
                fieldNames={{ label: "name", value: "id" }}
                placeholder="请选择项目"
                bordered={false}
                value={userProjectName}
              />
            </Space>
          </div>
        ) : null}
        <div className="user-content">
          <div className="user-company">{userInfo.orgName}</div>
          <div className="user-name">{userInfo.userName}</div>
        </div>
        <Dropdown
          overlayStyle={{ textAlign: "center" }}
          menu={{ items }}
          placement="bottomRight"
          arrow
        >
          <div className="user-action">
            <UpOutlined style={{ fontSize: 10, color: "#8287A0" }} />
            <DownOutlined style={{ fontSize: 10, color: "#8287A0" }} />
          </div>
        </Dropdown>
      </div>
    </Header>
  );
}
