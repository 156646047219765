import React, { useEffect, useState } from "react";
import { Breadcrumb, Layout } from "antd";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";
// eslint-disable-next-line import/no-cycle
import { router } from "../../../router/route";
import {
  GetRouteByPath,
  GetRoutePidByID,
  menuItem,
} from "../../../utils/router";

const { Header } = Layout;

function HeaderView() {
  // 当前路由
  const location = useLocation();
  // 菜单栏数据
  const menuList = useSelector((state: any) => state.Menu.menuList);
  // 头部导航栏数据
  const [header, setHeader] = useState<ItemType[]>([]);

  // 获取菜单栏每一级标题
  const getMenuTitle = (id: number, titleList: string[]) => {
    const pMenuObj = GetRoutePidByID(id, menuList);
    if (pMenuObj) {
      titleList.unshift(pMenuObj.meta.title);
      getMenuTitle(pMenuObj.meta.id, titleList);
    }
    return titleList;
  };
  // 设置当前面包屑;
  const setBreadcrumbTitle = () => {
    const current = GetRouteByPath(location.pathname, router);
    if (current) {
      setHeader(
        getMenuTitle(current ? current.meta.id : 1000, [
          current.meta.title,
        ]).map((i) => {
          return { title: i };
        })
      );
    }
  };

  // componentDidMount
  useEffect(() => {
    setBreadcrumbTitle();
  }, [location]);

  return (
    <Header>
      <Breadcrumb items={header}>
        {/* {header.map((i, j) => ( */}
        {/*  <Breadcrumb.Item key={j}>{i}</Breadcrumb.Item> */}
        {/* ))} */}
      </Breadcrumb>
    </Header>
  );
}

export default HeaderView;
