import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo:
    JSON.parse(localStorage.getItem("TMJ-ADMIN-USER-INFO") as string) || [],
};

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    SET_USERINFO(state, action) {
      state.userInfo = action.payload;
      localStorage.setItem(
        "TMJ-ADMIN-USER-INFO",
        JSON.stringify(action.payload)
      );
    },
  },
});

export const { SET_USERINFO } = userInfoSlice.actions;

export const UserInfo = userInfoSlice.reducer;
