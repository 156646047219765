import React, { useEffect, useState } from "react";
import { Form, Input, Button, message } from "antd";
import { CommonAPI } from "../../../../../api";
import { TableType } from "../../interface";
import { exceptChinese } from "../../../../../utils/validateUtils";

export function ChangePassword(props: TableType) {
  const { infoUser } = props;
  const [form] = Form.useForm();
  const handleSubmit = async (value: any) => {
    const res = await CommonAPI.updatePassword({
      userAccount: infoUser.userAccount,
      originalPassword: btoa(value.oldPassword),
      newPassword: btoa(value.newPassword),
    });
    if (res.code === 200) {
      message.success(res.message || res.msg);
    }
  };
  const PasswordAffirm = (rules: any, value: any) => {
    if (!value || form.getFieldValue("newPassword") === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("密码不同，请重新输入"));
  };
  return (
    <Form
      onFinish={handleSubmit}
      form={form}
      labelCol={{ span: 5 }}
      wrapperCol={{ offset: 2 }}
      style={{ width: 300 }}
    >
      <Form.Item
        label="原密码"
        name="oldPassword"
        rules={[{ required: true, message: "原密码不能为空!" }]}
      >
        <Input allowClear placeholder="请输入原密码" />
      </Form.Item>
      <Form.Item
        label="新密码"
        name="newPassword"
        rules={[
          { required: true, message: "新密码不能为空!" },
          { min: 6, max: 20, message: "新密码6-20位!" },
        ]}
      >
        <Input.Password
          allowClear
          placeholder="请输入新密码"
          onInput={exceptChinese}
        />
      </Form.Item>
      <Form.Item
        label="确认密码"
        name="newPasswordAffirm"
        rules={[
          { required: true, message: "确认密码不能为空!" },
          {
            validator: PasswordAffirm,
          },
        ]}
      >
        <Input.Password
          allowClear
          placeholder="请输入确认密码"
          onInput={exceptChinese}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 7 }}>
        <Button size="large" type="primary" htmlType="submit">
          提交
        </Button>
      </Form.Item>
    </Form>
  );
}
