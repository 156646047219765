import React, { useEffect, useRef, useState } from "react";
import "./index.scss";

export interface tabItemEntity {
  id?: number;
  name: string;
}

interface selfTabsProps {
  tabList: tabItemEntity[];
  children: React.ReactNode;
  currentTabIndex: number;
  tabItemClick: (index: number) => void;
  tabBgColor: string;
  activeTabItemBgColor: string;
  showLine: boolean;
}

function SelfTabs({
  tabList,
  children,
  currentTabIndex,
  tabItemClick,
  tabBgColor,
  activeTabItemBgColor,
  showLine = false,
}: selfTabsProps) {
  return (
    <div className="self-tabs">
      <div className="tab-wrap" style={{ backgroundColor: tabBgColor }}>
        {tabList.map((v, index: number) => (
          <div
            className="tab-item"
            style={{
              backgroundColor:
                currentTabIndex === (v.id ? v.id : index + 1)
                  ? activeTabItemBgColor
                  : "",
            }}
            onClick={() => tabItemClick(v.id ? v.id : index + 1)}
            key={index}
          >
            <div className="tab-item-content">
              <div> {v.name}</div>
              {showLine && currentTabIndex === index + 1 && (
                <div className="tab-item-line" />
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="tab-content">{children}</div>
    </div>
  );
}
export default SelfTabs;
